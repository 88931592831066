import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const API_URL = process.env.VUE_APP_MANAGER_API_URL;
document.title = "Voyado Onsite Manager";

export default createStore({
  state: {
    title: "Home",
    apiURL: API_URL,
    loader: false,
    prevCompanyId: "",
    activeRoute: "",
    cognitoInfo: {},
    dashboardInfo: {},
    menudataInfo: {},
    routedataInfo: {},
    filterInfo: {},
    dashboardFilter: {},
    emailforforgotpassword: "",
    email: "",
    session: "",
    globalSearch: {
      value: "",
      matchMode: "contains", // You can adjust this based on your needs
    },
    routes: [
      { Name: "/", Value: "home" },
      { Name: "/deals", Value: "modifydeal" },
      { Name: "/site/list", Value: "sitelist" },
      { Name: "/site/create", Value: "sitecreate" },
      { Name: "/site/edit/:id", Value: "siteedit" },
      { Name: "/sites", Value: "newsitelist" },
      { Name: "/newsite/create", Value: "newsitecreate" },
      { Name: "/newsite/edit/:id", Value: "newsiteedit" },
      { Name: "/newsite/copy/:id", Value: "newsitecopy" },
      { Name: "/site/copy/:id", Value: "sitecopy" },
      { Name: "/deal/list", Value: "deallist" },
      // { Name: "/deal/create", Value: "dealcreate" },
      { Name: "/deal/edit/:id", Value: "dealedit" },
      { Name: "/performance", Value: "performance" },
      { Name: "/myambassadors", Value: "myambassadors" },
      { Name: "/myfriends", Value: "myfriends" },
      { Name: "/relations", Value: "relations" },
      { Name: "/user/list", Value: "userlist" },
      { Name: "/user/create", Value: "usercreate" },
      { Name: "/user/edit/:id", Value: "useredit" },
      { Name: "/companies", Value: "companylist" },
      { Name: "/company/create", Value: "companycreate" },
      { Name: "/company/edit/:id", Value: "companyedit" },
      { Name: "/changelog/:id", Value: "changelog" },
      { Name: "/changelog/:id", Value: "changelog" },
      { Name: "/contact/list", Value: "contactlist" },
      { Name: "/errorsummary", Value: "errorsummary" },
      { Name: "/sitechangelog/:id", Value: "siteChangelog" },
      { Name: "/companychangelog/:id", Value: "companyChangelog" },
      { Name: "/editdealnew/:id", Value: "editdeal" },
      { Name: "/editdeal/:id", Value: "editdealnew" },
      { Name: "/newonsitemessage", Value: "newonsitemessage" },

      { Name: "/deals/deallist", Value: "managedeallistnew" },
      { Name: "/deals/deallistnew", Value: "managedeallist" },
      { Name: "/copydealnew/:id", Value: "copydeal" },
      { Name: "/copydeal/:id", Value: "copydealnew" },
      { Name: "/searchdeal/:id", Value: "dealsearch" },
      { Name: "/deals/create", Value: "managedealupdateCreate" },
      { Name: "/deals/edit/:id", Value: "managedealupdateById" },
      {
        Name: "/updatetranslation",
        Value: "updatetranslation",
      },
      { Name: "/newdeal/newcreate", Value: "newdealcreate" },
      { Name: "/neweditdeal/:id", Value: "neweditdeal" },
      { Name: "/notfound", Value: "notfound" },
      { Name: "/signupwizard", Value: "signupwizard" },
      { Name: "/newdealwizard", Value: "newdealwizard" },
      // { Name: "/managedealupdate", Value: "managedealupdate" },
      { Name: "/overview", Value: "overview" },
      { Name: "/contentgenerator", Value: "contentgenerator" },
    ],
  },
  plugins: [
    createPersistedState({
      paths: [
        "cognitoInfo",
        "dashboardInfo",
        "filterInfo",
        "dashboardFilter",
        "menudataInfo",
        "routedataInfo",
      ],
    }),
  ],
  getters: {
    GetRoutePath: (state) => (pathName) => {
      var routeObj = state.routes.filter(function (path) {
        if (path.Value == pathName) {
          return path.Name;
        }
      });
      if (routeObj.length > 0) {
        return routeObj[0].Name;
      }
    },
    getGlobalSearch: (state) => {
      return state.globalSearch;
    },
  },
  mutations: {
    setGlobalSearch(state, search) {
      state.globalSearch = search;
    },
    showLoader(state) {
      state.loader += 1;
    },
    hideLoader(state) {
      if (state.loader > 0) state.loader -= 1;
      else state.loader = 0;
    },
    replaceTitle(state, newtitle) {
      state.title = newtitle;
      document.title = "Voyado Onsite Manager - " + newtitle;
    },
    setForgotPasswordEmail(state, info) {
      state.emailforforgotpassword = info;
    },
    resetForgotPasswordEmail(state) {
      state.emailforforgotpassword = "";
    },
    setLoginEmail(state, info) {
      state.email = info;
    },
    resetLoginEmail(state) {
      state.email = "";
    },
    setSession(state, info) {
      state.session = info;
    },
    resetSession(state) {
      state.session = "";
    },
    setCognitoToken(state, info) {
      state.cognitoInfo.token = info;
    },
    setCognitoUsername(state, info) {
      state.cognitoInfo.username = info;
    },
    setCognitoEmail(state, info) {
      state.cognitoInfo.email = info;
    },
    setCognitoInfo(state, info) {
      state.cognitoInfo = info;
    },
    resetCognitoInfo(state) {
      state.cognitoInfo = {};
    },
    setMenudataInfo(state, info) {
      state.menudataInfo = info;
    },
    resetMenudataInfo(state) {
      state.menudataInfo = {};
    },
    setRoutedataInfo(state, info) {
      state.routedataInfo = info;
    },
    resetRoutedataInfo(state) {
      state.routedataInfo = {};
    },
    replaceCompanyId(state, newid) {
      state.cognitoInfo.companyid = newid;
      state.prevCompanyId = newid;

      state.cognitoInfo.companylist.forEach((element) => {
        element.selected = false;
      });

      var _filtered = state.cognitoInfo.companylist.filter(
        (a) => a.value == newid
      )[0];
      _filtered.selected = true;
      state.cognitoInfo.companyname = _filtered.text;
    },
    resetDashbboardInfoData(state) {
      state.dashboardInfo.data = "";
    },
    setDashboardInfo(state, info) {
      state.dashboardInfo = info;
    },
    resetDashboardInfo(state) {
      state.dashboardInfo = {};
    },
    resetDashboardLastSync(state) {
      state.dashboardInfo.lastSync = null;
    },
    resetDashbboardFilter(state) {
      state.dashboardFilter = "";
    },
    setDashboardFilter(state, filter) {
      state.dashboardFilter = filter;
    },
    setGlobalFilter(state, text) {
      state.filterInfo.global = text;
    },
    resetDealFilter(state) {
      state.filterInfo = {};
    },
    removeGlobalFilter(state) {
      state.filterInfo.global = "";
    },
    setActiveRoute(state, value) {
      state.activeRoute = value;
    },
  },
});
